import { Button } from "@apollosproject/canvas-ui-web";
import type { MetaFunction } from "@remix-run/node";

export const meta: MetaFunction = () => {
  return [
    { title: "Apollos" },
    { name: "description", content: "Welcome to Apollos!" },
  ];
};

export default function Index() {
  return (
    <div className="prose dark:prose-invert mx-auto text-center flex content-center items-center flex-col mt-20">
      <svg
        width="158"
        height="158"
        viewBox="0 0 158 158"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.25">
          <g clipPath="url(#clip0_1015_20755)">
            <rect
              x="63.644"
              width="113.814"
              height="113.814"
              rx="32"
              transform="rotate(34 63.644 0)"
              fill="#00676D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M38.7409 87.732L90.5801 50.7899L100.848 57.7154L86.0148 119.619L38.7409 87.732ZM87.3258 66.0814L58.7215 87.5461L78.7274 101.04L87.9137 66.478L87.3258 66.0814Z"
              fill="white"
            />
            <g style={{ mixBlendMode: "overlay" }} opacity="0.5">
              <rect
                x="63.644"
                width="113.814"
                height="113.814"
                transform="rotate(34 63.644 0)"
                fill="url(#paint0_linear_1015_20755)"
              />
            </g>
          </g>
        </g>
        <g opacity="0.5">
          <g clipPath="url(#clip1_1015_20755)">
            <rect
              x="41.2175"
              y="7.94141"
              width="113.814"
              height="113.814"
              rx="32"
              transform="rotate(17 41.2175 7.94141)"
              fill="#00676D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M43.0528 99.121L81.8261 48.6367L93.6697 52.2577L97.5838 115.793L43.0528 99.121ZM83.1848 64.2115L62.106 93.1015L85.183 100.157L83.863 64.4189L83.1848 64.2115Z"
              fill="white"
            />
            <g style={{ mixBlendMode: "overlay" }} opacity="0.5">
              <rect
                x="41.2175"
                y="7.94141"
                width="113.814"
                height="113.814"
                transform="rotate(17 41.2175 7.94141)"
                fill="url(#paint1_linear_1015_20755)"
              />
            </g>
          </g>
        </g>
        <g clipPath="url(#clip2_1015_20755)">
          <rect
            x="22.093"
            y="22.0925"
            width="113.814"
            height="113.814"
            rx="32"
            fill="#00676D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.5063 108.751L72.8253 49.1367H85.21L107.529 108.751H50.5063ZM78.6782 63.6337L66.9671 97.4242H91.0985L79.3874 63.6337H78.6782Z"
            fill="white"
          />
          <g style={{ mixBlendMode: "overlay" }} opacity="0.5">
            <rect
              x="22.093"
              y="22.0925"
              width="113.814"
              height="113.814"
              fill="url(#paint2_linear_1015_20755)"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1015_20755"
            x1="63.644"
            y1="0"
            x2="144.614"
            y2="80.9702"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1015_20755"
            x1="41.2175"
            y1="7.94141"
            x2="122.188"
            y2="88.9116"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1015_20755"
            x1="22.093"
            y1="22.0925"
            x2="103.063"
            y2="103.063"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_1015_20755">
            <rect
              x="63.644"
              width="113.814"
              height="113.814"
              rx="32"
              transform="rotate(34 63.644 0)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip1_1015_20755">
            <rect
              x="41.2175"
              y="7.94141"
              width="113.814"
              height="113.814"
              rx="32"
              transform="rotate(17 41.2175 7.94141)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip2_1015_20755">
            <rect
              x="22.093"
              y="22.0925"
              width="113.814"
              height="113.814"
              rx="32"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>

      <h1>Welcome to Apollos!</h1>
      <Button
        href="https://apollos.app"
        className={"not-prose"}
        surface="solid"
      >
        Learn more
      </Button>
    </div>
  );
}
